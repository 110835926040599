import initialState from '../stores/lib/InitialState';
import { FETCHED_USER_INFO } from '../event-types';

export default (state = initialState.userCredentials, action) => {
    switch (action.type) {
    case FETCHED_USER_INFO:
        return {
            ...state,
            userData: action.payload && action.payload.userData,
            profileData: action.payload && action.payload.profileData,
            isCredentialStateUpdated: true,
        };
    default:
        return state;
    }
};
