import { combineReducers } from 'redux';
import productCategories from './product-categories';
import articleCategories from './article-categories';
import allPages from './all-pages';

import survey from '../reducers/SurveySlice';
import surveyQuestions from '../reducers/SurveyQuestionsSlice';
import userCredentials from '../reducers/UserInformation';
import SamplingCampaignCheck from '../reducers/SamplingCampaignCheck';
import IdbindRetailers from '../reducers/IdbindRetailers';
import LotteryCampaignCheck from './LotteryCampaignCheck';
import userAllData from './userAllData';

export default combineReducers({
    productCategories,
    articleCategories,
    allPages,
    survey,
    SamplingCampaignCheck,
    surveyQuestions,
    userCredentials,
    IdbindRetailers,
    LotteryCampaignCheck,
    userAllData,
});
