/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from '../stores/lib/InitialState';

const surveyQuestionsSlice = createSlice({
    initialState: initialState.surveyQuestions,
    name: 'surveyQuestions',
    reducers: {
        questionsFetching: (state) => {
            state.isFetching = true;
            state.isError = false;
            state.error = '';
        },
        questionsErrorFetching: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.error = payload || '';
        },
        questionsSuccessFetching: (state, { payload }) => {
            state.isFetching = false;
            state.result = payload || [];
            state.successModelStatus = true;
        },
    },
});

const { actions, reducer } = surveyQuestionsSlice;

export const { questionsFetching, questionsErrorFetching, questionsSuccessFetching } = actions;

export default reducer;
