/* eslint-disable no-restricted-syntax */
/**
 * To check the storage status.
 */
const checkStorage = () => ((typeof (Storage) !== 'undefined'));

/**
 * Retrive an object from session storage.
 * @param  string key
 * @return mixed
 */
export const storageGet = (key) => {
    if (checkStorage()) {
        const item = window.sessionStorage.getItem(key);
        if (!item) return;
        return item;
    }
};

/**
 * Save some value to session storage.
 * @param string key
 * @param string value
 */
export const storageSet = async (key, value) => {
    if (checkStorage()) {
        await window.sessionStorage.setItem(key, value);
    }
};

/**
 * Save some value to session storage.
 * @param items object
 */
export const storageSetItems = async (items) => {
    if (checkStorage()) {
        // eslint-disable-next-line guard-for-in
        for (const key in items) {
            window.sessionStorage.setItem(key, items[key]);
        }
    }
};

/**
 * Remove element from session storage.
 * @param string key
 */
export const storageKeyRemove = async (key) => {
    if (checkStorage()) {
        await window.sessionStorage.removeItem(key);
    }
};

/**
 * Remove the multiple element from session storage.
 * @param string key
 */
export const storageKeysRemove = async (items) => {
    if (checkStorage()) {
        for (const key of items) {
            if (window.sessionStorage.key) window.sessionStorage.removeItem(key);
        }
    }
};

export const GetEmailVerified = () => {
    if (checkStorage()) {
        const userSession = JSON.parse(window.sessionStorage.getItem(`${window.location.hostname}crmDetails`));
        return (userSession && userSession.emailVerified !== null) ? Boolean(userSession.emailVerified) : false;
    }
};
