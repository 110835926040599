/* eslint-disable react/jsx-props-no-spreading */
import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
// import { persistStore } from 'redux-persist';
import Head from 'next/head';
import withReduxStore from '../src/redux/stores/lib/with-redux-store';
import routerEvents from '../src/helpers/RouterEvents';
import { PgDataLayer, GtmHead } from '../src/components/Scripts';

class MyApp extends App {
    componentDidMount() {
        if (typeof window !== 'undefined') {
            routerEvents();
        }
        if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
            const ReactDOM = require('react-dom');
            const axe = require('@axe-core/react');
            const config = {
                runOnly: {
                    type: 'tag',
                    values: ['wcag2aa', 'best-practice'],
                },
                resultTypes: ['violations'],
            };
            axe(React, ReactDOM, 1000, config);
        }
    }

    render() {
        const { Component, pageProps, reduxStore } = this.props;
        return (
            <>
                <Head>
                    <PgDataLayer />
                    <GtmHead />
                </Head>
                <Provider store={reduxStore}>
                    <Component {...pageProps} />
                </Provider>
            </>
        );
    }
}

export default withReduxStore(MyApp);
