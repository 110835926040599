import React from 'react';

const SeoSchema = (jsonLdCollection) => {
    const isValidCollection = jsonLdCollection && jsonLdCollection.items && jsonLdCollection.items.length > 0;

    if (!isValidCollection) {
        return null;
    }

    return jsonLdCollection.items.map((data, index) => (
        <script key={index} type="application/ld+json">
            {`${JSON.stringify(data.json)}`}
        </script>
    ));
};

export default SeoSchema;
