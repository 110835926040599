import { FETCHED_ARTICLE_CATEGORIES } from '../event-types';

export default (state = [], action) => {
    switch (action.type) {
    case FETCHED_ARTICLE_CATEGORIES:
        return action.payload;
    default:
        return state;
    }
};
