import React from 'react';

const Canonical = ({ links }) => {
    // if (!links || links.length < 1) {
    //   console.log("emptyyyyy")
    //     return null;
    // }
    return (
      <>
            {links && links.length>0 && links.map((link) => {
        console.log("link ", link);
        return <link rel="canonical" href={link.canonical} />
       })}  
    </>
    );
};

export default Canonical;
