/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from '../stores/lib/InitialState';

const IdbindRetailers = createSlice({
    initialState: initialState.idbindRetailer,
    name: 'idbindRetailers',
    reducers: {
        idBindRetailerFetching: (state) => {
            state.isFetching = true;
            state.isError = false;
            state.error = '';
        },
        idBindRetailerErrorFetching: (state, { payload }) => {
            state.isFetching = false;
            state.isError = true;
            state.error = payload || '';
        },
        idBindRetailerSuccessFetching: (state, { payload }) => {
            state.isFetching = false;
            state.result = payload || [];
            state.successModelStatus = true;
        },
    },
});

const { actions, reducer } = IdbindRetailers;

export const { idBindRetailerFetching, idBindRetailerErrorFetching, idBindRetailerSuccessFetching } = actions;

export default reducer;
