/* eslint-disable import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// import { persistReducer } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/session';
import { composeWithDevTools } from 'redux-devtools-extension';
import exampleInitialState from '../lib/InitialState';

import reducers from '../../reducers';

const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
    duration: true,
    timestamp: true,
    level: 'log',
    logErrors: true, // should the logger catch, log, and re-throw errors?
    diff: true, // (alpha) show diff between states?
});

// const persistConfig = {
//     key: 'root',
//     storage: storageSession,
// };
// const persistedReducer = persistReducer(persistConfig, reducers);

export default function initializeStore(initialState = exampleInitialState) {
    const middleware = [thunk];
    if (process.env.NODE_ENV !== 'production') {
        middleware.push(logger);
    }

    return createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunk)));
}
