import initialState from '../stores/lib/InitialState';
import { USER_FROM_CAMPAIGN } from '../event-types';

export default (state = initialState.userFromCampaign, action) => {
    switch (action.type) {
    case USER_FROM_CAMPAIGN:
        return {
            ...state,
            userFromCampaignFlag: typeof action.payload === 'boolean' && action.payload,
        };
    default:
        return state;
    }
};
