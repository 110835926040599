const data = {
    surveyQuestions: {
        isFetching: false,
        isError: false,
        status: '',
        result: [],
        error: '',
    },
    idbindRetailer: {
        isFetching: true,
        isError: false,
        status: '',
        result: [],
        error: '',
    },
    GetRetailerId: {
        retailerId: 0,
    },
    idbinding: {
        isBinded: false,
        status: '',
        error: '',
        unbinddata: {},
        binddata: {},
    },
    userCredentials: {
        userData: {},
        profileData: {},
        isCredentialStateUpdated: false,
    },

    userAllData: {
        userData: {},
        profileData: {},
        isCredentialStateUpdated: false,
    },
    userFromCampaign: {
        userFromCampaignFlag: false,
    },
    userFromLottery: {
        userFromLotteryFlag: false,
    },
    survey: {
        currentQuestion: 0,
        maxQuestion: 13,
        questions: {},
        answers: {},
        error: false,
    },
    productCategories: {},
    articleCategories: {},
    allPages: {},
};

export default data;
