import initialState from '../stores/lib/InitialState';
import { USER_FROM_LOTTERY } from '../event-types';

export default (state = initialState.userFromLottery, action) => {
    switch (action.type) {
    case USER_FROM_LOTTERY:
        return {
            ...state,
            userFromLotteryFlag: typeof action.payload === 'boolean' && action.payload,
        };
    default:
        return state;
    }
};
