module.exports = function routerEvents() {
    const Router = require('next/router').default;

    function onRouteUpdate() {
        if (typeof window !== 'undefined') {
            const index = window.dataLayer && window.dataLayer
                .map((pageView) => pageView.event)
                .indexOf('virtualPageview');
            if (window.dataLayer) {
                window.dataLayer.splice(index, 1);
                window.dataLayer.push({ event: 'virtualPageview' });
            }
        }
    }

    function onRouteChangeStart() { // Triggers at the initial Route Change
    }

    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteUpdate);
};
