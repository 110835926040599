import React from 'react';
import { GetEmailVerified } from '../../helpers/StorageEvents';

const PgDataLayer = () => {
    const PGDL = {
        'user': {
            'sourceID': process.env.PGDL_USER_SOURCE_ID,
            'marketingProgramNbr': process.env.PGDL_USER_MARKETING_PROGRAM_NBR,
            'type': process.env.PGDL_USER_TYPE,
            'ID': process.env.PGDL_USER_ID,
        },
        'GTM': {
            'SiteCountry': process.env.PGDL_GTM_SITE_COUNTRY,
            'SiteEnvironment': process.env.PGDL_GTM_SITE_ENV,
            'SiteTouchpoint': process.env.PGDL_GTM_SITE_TOUCH_PT,
            'SiteHost': process.env.PGDL_GTM_SITE_HOST,
            'SiteTechnicalAgency': process.env.PGDL_GTM_SITE_TECH_AGENCY,
            'SiteLocalContainer': process.env.PGDL_GTM_SITE_LOCAL_CONTAINER,
            'GoogleAnalyticsLocal': process.env.PGDL_GTM_GOOGLE_ANALYTICS_LOCAL,
            // 'GoogleAnalyticsStaging': 'UA-38384539-1',
            'GoogleAnalyticsBrand': process.env.PGDL_GTM_GOOGLE_ANALYTICS_BRAND,
            'GoogleAnalyticsSiteSpeedSampleRate': process.env.PGDL_GTM_GOOGLE_ANALYTICS_SITE_SPEED_SAMPLE_RATE,
            'GoogleAnalyticsAllowLinker': process.env.PGDL_GTM_GOOGLE_ANALYTICS_ALLOW_LINKER,
            'GoogleAnalyticsLinkerDomains': process.env.PGDL_GTM_GOOGLE_ANALYTICS_LINKER_DOMAINS,
            'GoogleAnalyticsOptimizeContainerID': process.env.PGDL_GTM_GOOGLE_ANALYTICS_OPTIMIZE_CONTAINER_ID,
            'ConsentOverlay': process.env.PGDL_GTM_CONSENT_OVERLAY,
            'ConsentOverlayID': process.env.PGDL_GTM_CONSENT_OVERLAY_ID,
            'SiteGDPR': process.env.PGDL_GTM_SITE_GDPR,
            'ConsentRequiredClientID': process.env.PGDL_GTM_CONSENT_REQ_CLIENT_ID,
            'GoogleAnalyticsConsentRequired': process.env.PGDL_GTM_GOOGLE_ANALYTICS_CONSENT_REQ,
            'FacebookConnectAppID': process.env.PGDL_GTM_FACEBOOK_CONNECT_APPLE_ID,
            'FacebookConnectLocale': process.env.PGDL_GTM_FACEBOOK_CONNECT_LOCALE,
            'FacebookRemarketingID': process.env.PGDL_GTM_FACEBOOK_REMARKETING_ID,
            'BINPlatform': process.env.PGDL_GTM_BIN_PLATFORM,
            'GoogleReCaptcha': process.env.PGDL_GTM_GOOGLE_RECAPTCHA,
            'GoogleAnalyticsDisabled': process.env.PGDL_GTM_GOOGLE_ANALYTICS_DISABLED,
            'GoogleAnalyticsStaging': process.env.PGDL_GTM_GOOGLE_ANALYTICS_STAGING,
            'SiteStagingContainer': process.env.PGDL_GTM_SITE_STAGING_CONTAINER,
            'Iovation': process.env.PGDL_GTM_IOVATION,
            'IovationContentType': process.env.PGDL_GTM_IOVATION_CONTENT_TYPE,
            'Lotame': process.env.PGDL_GTM_LOTAME,
            'Monetate': process.env.PGDL_GTM_MONETATE,
            'Lytics': process.env.PGDL_GTM_LYTICS,
            'Segment': process.env.PGDL_GTM_SEGMENT,
            'GoogleAnalyticsGA4MeasurementID': process.env.GA_MEASUREMENT_ID,
            'GA4propertyID': '294482095',
            'MeasurementID': process.env.GA_MEASUREMENT_ID,
        },
    };
    if (!PGDL) {
        return null;
    }
    if ((typeof window !== 'undefined' && window.sessionStorage.getItem('isUserLoggedIn')) && GetEmailVerified()) {
        PGDL.user.type = 'member';
        PGDL.user.ID = window.sessionStorage.getItem(`${window.location.hostname}crmDetails`);
    }

    const PGDLObject = JSON.stringify(PGDL);
    return (
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `var PGdataLayer = ${PGDLObject}` }} />
    );
};

export default PgDataLayer;
