import React from 'react';

const gtm = () => (
    <noscript>
        <iframe
            title="GTM"
            src="https://www.googletagmanager.com/ns.html?id=GTM-N94XXFB"
            height="0"
            width="0"
            style={{ display: 'none' }}
        />
    </noscript>
);

export default gtm;
