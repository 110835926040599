import { FETCHED_ALL_PAGES } from '../event-types';

export default (state = [], action) => {
    switch (action.type) {
    case FETCHED_ALL_PAGES:
        return action.payload;
    default:
        return state;
    }
};
