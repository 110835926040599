/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from '../stores/lib/InitialState';

const surveySlice = createSlice({
    initialState: initialState.survey,
    name: 'survey',
    reducers: {
        selectAnswer: (state, { payload: { option, question, isMultiSelect } }) => {
            if (!state.answers[question]) {
                state.answers[question] = [];
            }
            if (!isMultiSelect) {
                state.answers[question][0] = { ...option };
                return state;
            }

            const existingIndex = state.answers[question].findIndex((answer) => answer.optionId === option.optionId);
            if (existingIndex === -1) {
                state.answers[question].push(option);
            } else {
                state.answers[question].splice(existingIndex, 1);
            }
        },
        navigateToNextQuestion: (state) => {
            const conditionOne = !state.answers[state.currentQuestion];
            const conditionTwo = !conditionOne && state.answers[state.currentQuestion].length <= 0;
            if (conditionOne || conditionTwo) {
                state.error = true;
                return;
            }
            if (state.currentQuestion === state.maxQuestion) {
                state.error = false;
                state.currentQuestion = state.maxQuestion;
                return state;
            }
            const skipToOptionNine = state.answers[2] && state.answers[2].findIndex((answer) => answer.optionId === '3222' || answer.optionId === '1279');
            if (state.currentQuestion === 2 && skipToOptionNine >= 0) {
                state.error = false;
                state.currentQuestion = 8;
                state.answers[3] = [];
                state.answers[4] = [];
                state.answers[5] = [];
                state.answers[6] = [];
                state.answers[7] = [];
                return state;
            }
            const skipToOptionTen = state.answers[7] && state.answers[7].findIndex((answer) => (answer.optionId >= '3250' && answer.optionId <= '3260') || (answer.optionId >= '1307' && answer.optionId <= '1317'));
            if (state.currentQuestion === 7 && skipToOptionTen >= 0) {
                state.error = false;
                state.currentQuestion = 9;
                state.answers[8] = [];
                return state;
            }
            state.error = false;
            state.currentQuestion++;
        },
        navigateToPreviousQuestion: (state) => {
            if (state.currentQuestion <= 0) {
                state.currentQuestion = 0;
                return state;
            }
            const shouldSkipToOptionThree = state.answers[2] && state.answers[2].findIndex((answer) => answer.optionId === '3222' || answer.optionId === '1279');
            if (state.currentQuestion === 8 && shouldSkipToOptionThree >= 0) {
                state.currentQuestion = 2;
                return state;
            }
            const shouldSkipToOptionEight = state.answers[7] && state.answers[7].findIndex((answer) => (answer.optionId >= '3250' && answer.optionId <= '3260') || (answer.optionId >= '1307' && answer.optionId <= '1317'));
            if (state.currentQuestion === 9 && shouldSkipToOptionEight >= 0) {
                state.currentQuestion = 7;
                return state;
            }
            state.currentQuestion--;
        },
        updateTextField: (state, { payload: { value, currentQuestion } }) => {
            if (currentQuestion !== 13) {
                const textFieldIndex = state.answers[currentQuestion].findIndex((answer) => answer.helptext === 'Other');
                if (textFieldIndex < 0) return;
                state.answers[currentQuestion][textFieldIndex]['textValue'] = value || '';
                return state;
            }
            state.answers[currentQuestion] = value || '';
        },
    },
});

const { actions, reducer } = surveySlice;

export const { selectAnswer, navigateToNextQuestion, navigateToPreviousQuestion, updateTextField } = actions;

export default reducer;
